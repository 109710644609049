import { twMerge } from 'tailwind-merge';
type PageHeaderProps = {
  image: string;
  title: string;
  imageStyles?: string;
};
export const PageHeader = ({ image, title, imageStyles }: PageHeaderProps) => {
  return (
    <section className=" relative flex h-[calc(9.938rem+4.375rem)] items-center justify-center overflow-hidden text-white">
      <div className="absolute inset-0 z-10 bg-[rgba(0,0,0,0.2)]"></div>
      <img
        src={image}
        className={twMerge(`absolute w-full h-full object-cover`, imageStyles)}
        alt="Page header"
      />
      <h1 className="relative z-20 text-5xl font-bold leading-[3.657rem]">
        {title}
      </h1>
    </section>
  );
};
