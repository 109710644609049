import { ReactNode } from 'react';

type ContentLayoutProps = {
  children?: ReactNode;
};
export const ContentLayout = ({ children }: ContentLayoutProps) => {
  return (
    <main className="mx-auto max-w-[71.25rem]   px-[2.188rem]">{children}</main>
  );
};
