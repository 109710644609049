import aboutBackgroundHeader from '@/assets/aboutusbackground.jpg';
import { ContentLayout, PageHeader } from '@/components/Layouts';
import aboutUs1 from '@/assets/aboutus1.jpeg';
import aboutUs2 from '@/assets/aboutus2.png';
import star from '@/assets/icons/star.png';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { AnimatePresence } from 'framer-motion';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { wrap } from '@/utils/wrap';

const sections = [
  {
    title: 'Mission',
    description: `My mission is to empower individuals through the disciplines of
                martial arts. I aim to cultivate not only physical strength and
                skill but also mental resilience and personal growth. By
                fostering a supportive and respectful training environment, I
                strive to help my students reach their full potential and
                develop a lifelong love for martial arts.`,
    image: aboutUs1,
    reverse: false,
  },
  {
    title: 'Vision',
    description: `My vision is to create a thriving community of martial artists
                who embody the values of discipline, respect, and perseverance.
                I aspire to make martial arts accessible to everyone, regardless
                of age or background, and to inspire a new generation of
                athletes to pursue excellence both on and off the mat. Through
                continuous learning and sharing of knowledge, I envision a
                future where our dojo is a cornerstone for personal and
                collective growth in the martial arts community`,
    image: aboutUs2,
    reverse: false,
  },
];

const testimonials: TestimonialCardType[] = [
  {
    name: 'Testimonial 1',
    imageUrl: aboutUs1,
    starCount: 2,
    description:
      'The instructors was fantastic (rupak) he teach in practical way how to do punch, block ,powerful kicks style and other technics on Muay Thai as well as other combat sports.And also the training fee was reasonable and worth it.I specially recommend to visit this Muay Thai gym in Pokhara.',
  },
  {
    name: 'Testiomnial 2',
    description:
      'The instructors was fantastic (rupak) he teach in practical way how to do punch, block ,powerful kicks style and other technics on Muay Thai as well as other combat sports.And also the training fee was reasonable and worth it.I specially recommend to visit this Muay Thai gym in Pokhara.',

    imageUrl: aboutUs1,
    starCount: 2,
  },
  {
    name: 'Testimonial 3',

    description:
      'The instructors was fantastic (rupak) he teach in practical way how to do punch, block ,powerful kicks style and other technics on Muay Thai as well as other combat sports.And also the training fee was reasonable and worth it.I specially recommend to visit this Muay Thai gym in Pokhara.',

    imageUrl: aboutUs1,
    starCount: 2,
  },

  {
    name: 'Testimonial 4',
    imageUrl: aboutUs1,
    starCount: 2,
    description:
      'The instructors was fantastic (rupak) he teach in practical way how to do punch, block ,powerful kicks style and other technics on Muay Thai as well as other combat sports.And also the training fee was reasonable and worth it.I specially recommend to visit this Muay Thai gym in Pokhara.',
  },
  {
    name: 'Testimonial 5',
    description:
      'The instructors was fantastic (rupak) he teach in practical way how to do punch, block ,powerful kicks style and other technics on Muay Thai as well as other combat sports.And also the training fee was reasonable and worth it.I specially recommend to visit this Muay Thai gym in Pokhara.',

    imageUrl: aboutUs1,
    starCount: 2,
  },
  {
    name: 'Testimonial 6',

    description:
      'The instructors was fantastic (rupak) he teach in practical way how to do punch, block ,powerful kicks style and other technics on Muay Thai as well as other combat sports.And also the training fee was reasonable and worth it.I specially recommend to visit this Muay Thai gym in Pokhara.',

    imageUrl: aboutUs1,
    starCount: 2,
  },
];

const testimonialVariant = {
  enter: (direction: number) => {
    return {
      opacity: 0,
      x: direction > 0 ? 1000 : -1000,
    };
  },
  center: {
    x: 0,
    opacity: 1,
  },
  exit: (direction: number) => {
    return {
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
};
const swipeConfidenceThreshold = 10000;
const swipePower = (offset: number, velocity: number) => {
  return Math.abs(offset) * velocity;
};
const padding = 'py-[3.75rem]';
export const AboutUs = () => {
  const [[page, direction], setPage] = useState([0, 0]);
  const [visibleItems, setVisibleItems] = useState(1);

  useEffect(() => {
    const updateVisibleItems = () => {
      const width = window.innerWidth;
      if (width < 540) {
        setVisibleItems(1);
      } else if (width < 1519) {
        setVisibleItems(2);
      } else {
        setVisibleItems(3);
      }
    };
    updateVisibleItems();
    window.addEventListener('resize', updateVisibleItems);
    return () => window.removeEventListener('resize', updateVisibleItems);
  }, []);

  const testimonialIndex = wrap(0, testimonials.length, page);
  const paginate = (newDirection: number) => {
    setPage([page + newDirection, newDirection]);
  };
  const displayedItems = [
    ...testimonials.slice(testimonialIndex, testimonialIndex + visibleItems),
  ];
  if (displayedItems.length < visibleItems) {
    displayedItems.push(
      ...testimonials.slice(0, visibleItems - displayedItems.length),
    );
  }
  return (
    <>
      <PageHeader image={aboutBackgroundHeader} title="About Me" />

      <ContentLayout>
        <>
          {/* about me description section */}
          <section
            className={`flex flex-col  gap-7 ${padding} items-center justify-center font-normal leading-[1.6rem]`}
          >
            <p className="text-center">
              {`I have dedicated many years to training in Kyokushin Karate and Muay
            Thai. My journey has taken me to various competitions, both in Nepal
            and internationally. I've had the privilege to compete in events
            like the First World University Muaythai Championship in Thailand,
            the First Prime Minister & Third National Muay Thai Championship,
            and the First Kyokushin Karate 2017. I've also participated in the
            All Nepal Kyokushin Budo Karate Community Tournament 2018, K Bag
            Muay Thai Championship 2016, and Sri Nath National Muay Thai
            Championship 2015, among others.`}
            </p>
            <p className="text-center">
              {`Each experience has been a valuable opportunity for growth and
            learning. I've always strived to do my best, and I'm grateful for
            the support and guidance I've received along the way. My passion for
            martial arts continues to drive me, and I am committed to sharing
            what I've learned with my students`}
            </p>
          </section>

          <div>
            {sections.map(({ description, title, image, reverse }, index) => (
              <AbouUsCard
                description={description}
                title={title}
                image={image}
                reverse={index % 2 == 0 ? (reverse = true) : false}
                key={index}
              />
            ))}
          </div>

          <section className=" flex flex-col items-center justify-center  gap-y-6 border-t-2 border-t-[rgba(217,217,217,0.5)] py-[3.75rem]">
            <h1 className=" text-center font-bold leading-[4.8rem] sm:text-4xl md:text-5xl">{`Student's Words`}</h1>
            <div className="relative h-[27.75rem] w-full">
              <div
                onClick={() => paginate(-visibleItems)}
                className="absolute top-1/2 flex size-[4.375rem] -translate-y-1/2  translate-x-[-130%] cursor-pointer items-center justify-center rounded-[50%] bg-[#E3F2FD] sm:hidden  lg:flex"
              >
                <FaChevronLeft size={20} />
              </div>

              <div
                onClick={() => paginate(visibleItems)}
                className="absolute right-0 top-1/2 flex size-[4.375rem] -translate-y-1/2  translate-x-[130%] cursor-pointer items-center justify-center rounded-[50%] bg-[#E3F2FD] sm:hidden  lg:flex"
              >
                <FaChevronRight size={20} />
              </div>
              <div className="relative size-full overflow-hidden">
                <AnimatePresence initial={false} custom={direction}>
                  <motion.div
                    key={page}
                    variants={testimonialVariant}
                    custom={direction}
                    initial="enter"
                    animate="center"
                    exit={'exit'}
                    transition={{
                      x: { type: 'spring', stiffness: 300, damping: 30 },
                      opacity: { duration: 0.2 },
                    }}
                    drag="x"
                    dragConstraints={{ left: 0, right: 0 }}
                    dragElastic={1}
                    onDragEnd={(e, { offset, velocity }) => {
                      const swipe = swipePower(offset.x, velocity.x);

                      if (swipe < -swipeConfidenceThreshold) {
                        paginate(1);
                      } else if (swipe > swipeConfidenceThreshold) {
                        paginate(-1);
                      }
                    }}
                  >
                    <TestmionialCardRow data={displayedItems} />
                  </motion.div>
                </AnimatePresence>
              </div>
            </div>

            <div className="flex items-center justify-center gap-x-3">
              <div
                onClick={() => paginate(-visibleItems)}
                className="flex size-[2.375rem] items-center justify-center rounded-[50%] bg-[#E3F2FD] sm:flex lg:hidden "
              >
                <FaChevronLeft size={20} />
              </div>

              <div
                onClick={() => paginate(visibleItems)}
                className="flex size-[2.375rem] items-center justify-center rounded-[50%] bg-[#E3F2FD] sm:flex lg:hidden "
              >
                <FaChevronRight size={20} />
              </div>
            </div>
          </section>
        </>
      </ContentLayout>
    </>
  );
};

export default AboutUs;

type TestimonailCardRowType = {
  data: TestimonialCardType[];
};
const TestmionialCardRow = ({ data }: TestimonailCardRowType) => {
  return (
    <div className="absolute flex  size-full sm:justify-center md:justify-around lg:justify-between">
      {data.map(({ description, imageUrl, name, starCount }) => (
        <TestimonialCard
          key={name}
          starCount={starCount}
          imageUrl={imageUrl}
          name={name}
          description={description}
        />
      ))}
    </div>
  );
};
type TestimonialCardType = {
  name: string;
  description: string;
  imageUrl: string;
  starCount: 1 | 2 | 3 | 4 | 5;
};
const TestimonialCard = ({
  description,
  name,
  imageUrl,
  starCount,
}: TestimonialCardType) => {
  const stars = [];
  for (let i = 0; i < starCount; i++) {
    stars.push(<img className="size-[1.563rem]" key={i} src={star} />);
  }
  return (
    <div className=" flex h-[27.75rem] w-[20.25rem] shrink-0  flex-col items-center gap-y-2 overflow-hidden  rounded-[1.25rem]  bg-[#E3F2FD] px-[2.575rem] py-5 ">
      <img
        src={imageUrl}
        className="size-[6.25rem] rounded-[50%] object-cover"
      />
      <h2 className="text-2xl font-bold leading-[2.4rem]">{name}</h2>
      <div className="flex">{stars}</div>
      <p className="grow overflow-hidden text-center text-sm font-normal leading-[1.525rem] ">
        {description}
      </p>
    </div>
  );
};

type AboutUsCardType = {
  title: string;
  image: string;
  description: string;
  reverse: boolean;
};

const AbouUsCard = ({
  title,
  image,
  description,
  reverse,
}: AboutUsCardType) => {
  return (
    <>
      {/* vision serction */}
      <div className=" mb-12 flex items-center justify-center">
        <div
          className={`flex  max-w-[66.688rem]  items-center  sm:flex-col  ${reverse ? ' justify-between md:flex-row' : 'md:flex-row-reverse'} sm:gap-6 `}
        >
          <h1 className="text-[3rem] font-bold leading-[3.45rem] sm:block md:hidden">
            {`${title}`}
          </h1>
          <div
            className={`flex w-full flex-[50%] ${reverse ? 'sm:justify-center md:justify-start' : 'sm:justify-center md:justify-end'}  `}
          >
            <div className="overflow-hidden rounded-[500px] sm:h-[18.5rem] sm:w-[17.1875rem] md:h-[20.5rem] md:w-[18.1875rem]  lg:h-[28.5rem] lg:w-[26.1875rem]">
              <img
                src={image}
                alt=""
                className="size-full  object-contain sm:[transform:translate(40px,40px)_scale(2)] md:[transform:translate(120px,84px)_scale(2.5)] lg:[transform:translate(136px,84px)_scale(2.5)]"
              />
            </div>
          </div>

          <div className="  flex w-full flex-[50%] flex-col  gap-3 ">
            <h1 className="text-[3rem] font-bold leading-[3.45rem] sm:hidden md:block">
              {`${title}`}
            </h1>
            <p className="font-normal leading-[1.6rem] sm:text-center">{`${description}`}</p>
          </div>
        </div>
      </div>
    </>
  );
};
