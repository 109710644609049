import contactBG from '@/assets/contactusbg.jpg';
import { ContentLayout, PageHeader } from '@/components/Layouts';
import { MdMyLocation, MdOutlineEmail } from 'react-icons/md';
import instaIcon from '@/assets/icons/instagram.png';
import youtubeIcon from '@/assets/icons/youtube.png';
import tiktokIcon from '@/assets/icons/tiktok.png';
import formBackground from '@/assets/form background.png';
import { useState } from 'react';
import { Spinner } from '@/components/Spinner';
import { useNotificationStore } from '@/stores/notification';

export const Contact = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [loading, setLoading] = useState(false);
  const { addNotification } = useNotificationStore();

  const handelInput = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = event.target;

    switch (name) {
      case 'name':
        setName(value);
        break;
      case 'email':
        setEmail(value);
        break;
      case 'message':
        setMessage(value);
        break;
      case 'phoneNumber':
        setPhoneNumber(value);
    }
  };

  const clearForm = () => {
    setName('');
    setEmail('');
    setMessage('');
    setPhoneNumber('');
  };

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    const formData = new FormData(event.target as HTMLFormElement);

    formData.append('access_key', 'd99d57c6-bc86-47f7-a0a0-f20697363a33');

    const object = Object.fromEntries(formData);
    const json = JSON.stringify(object);

    const res = await fetch('https://api.web3forms.com/submit', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: json,
    }).then((res) => res.json());

    clearForm();
    setLoading(false);

    if (res.success) {
      addNotification({ title: 'Message sent', type: 'success' });
      console.log(res);
    } else {
      addNotification({ title: 'Something went wrong', type: 'error' });
      console.log(res);
    }
  };

  return (
    <>
      <PageHeader
        title="Contact Me"
        image={contactBG}
        imageStyles="blur-[1.5px]"
      />

      <ContentLayout>
        <section className="flex min-h-[14.313rem] w-full flex-col items-center justify-center gap-y-6 py-[3.75rem]">
          <h1 className="text-center font-bold leading-[2.438rem] sm:text-4xl md:text-5xl">
            Get In Touch
          </h1>

          <div className="flex w-full flex-wrap items-center justify-around gap-5">
            {/* locatino div  */}
            <div>
              <div className="flex   flex-col items-center justify-center  gap-y-[0.563rem]">
                <span className=" flex items-center justify-center gap-[0.813rem]   text-center text-base font-semibold">
                  <MdMyLocation />
                  <h2 className="leading-[1.219rem]">Address</h2>
                </span>

                <h3 className="text-center text-[0.875rem] font-normal leading-[1.067rem]">
                  Pokhara, Rangasala
                </h3>
              </div>
            </div>

            {/* email div */}
            <div>
              <div className="flex  h-[3.563rem] w-[14.125rem] flex-col items-center justify-center  gap-y-[0.563rem]">
                <span className=" flex items-center justify-center gap-[0.813rem]   text-center text-base font-semibold">
                  <MdOutlineEmail />
                  <h2 className="leading-[1.219rem]">Email</h2>
                </span>

                <h3 className="text-center text-[0.875rem] font-normal leading-[1.067rem]">
                  rupaknepali2053@gmail.com
                </h3>
              </div>
            </div>

            {/* social div */}
            <div>
              <div className="flex h-[3.563rem] w-[14.125rem] flex-col items-center justify-center   gap-y-[0.563rem]">
                <span className=" flex items-center justify-center gap-[0.813rem]   text-center text-base font-semibold">
                  <h2 className="leading-[1.219rem]">Find me here</h2>
                </span>

                <span className="flex w-full items-center justify-around ">
                  <a
                    className="flex w-full items-center justify-center text-center text-[0.875rem] font-normal leading-[1.067rem]"
                    href="https://www.instagram.com/rupak_thehumblebeast"
                  >
                    <img src={instaIcon} className="w-[35%]" alt="Instagram" />
                  </a>
                  <a
                    className="flex w-full items-center justify-center text-center text-[0.875rem] font-normal leading-[1.067rem]"
                    href="https://www.youtube.com/@rupak_thehumblebeast"
                  >
                    <img src={youtubeIcon} className="w-2/5" alt="youtube" />
                  </a>
                  <a
                    className="flex w-full items-center  justify-center text-center text-[0.875rem] font-normal leading-[1.067rem]"
                    href="https://www.tiktok.com/@strongestmuaythai"
                  >
                    <img src={tiktokIcon} className="w-2/5" alt="TikTok" />
                  </a>
                </span>
              </div>
            </div>
          </div>
        </section>
      </ContentLayout>

      <section className="relative flex w-full  items-center justify-around overflow-hidden  py-[3.75rem] text-white sm:flex-col md:flex-row  md:items-center lg:h-[110vh] ">
        {/* <div className=" absolute flex size-full max-w-[90rem] items-center justify-around  py-[3.75rem] sm:flex-col sm:px-5 md:flex-row md:px-3 lg:px-[9.375rem] ">
         
        </div> */}

        <div className="flex max-w-[90rem] items-center justify-center sm:flex-col md:flex-row ">
          <div className=" flex gap-6 sm:relative sm:mb-20  sm:w-full sm:flex-col sm:px-6 sm:py-10 md:static md:mb-0 md:w-2/5">
            <img
              src={formBackground}
              className="absolute inset-0 size-full  "
              alt=""
            />
            <div className="absolute inset-0 size-full bg-[rgba(0,0,0,0.65)]"></div>
            <h1 className=" z-10 text-[2.5rem] font-bold leading-[4rem]">
              Hire Me For Personal Training
            </h1>
            <p className=" z-10 text-base font-normal leading-[1.6rem]">
              {`Unlock your potential with personalized training sessions tailored
              to your goals. Whether you're looking to improve your fitness,
              learn self-defense, or train for competitions, I provide expert
              guidance and support every step of the way. Let's work together to
              achieve your best self.`}
            </p>
          </div>

          {/* form section */}
          <form
            onSubmit={onSubmit}
            className=" z-10 flex h-[38.053rem] flex-col justify-between  gap-[2.813rem] rounded-xl border p-[3.75rem] font-medium leading-5 sm:border-black sm:text-black md:w-80 md:border-blue-50  md:text-white lg:w-[26.125rem]"
          >
            <span className="flex h-[12%] w-full flex-col">
              <label htmlFor="fullName">Full Name</label>
              <input
                className="h-10 border-b-2 border-gray-200 bg-transparent outline-none transition-colors duration-500 sm:focus:border-black md:focus:border-white "
                type="text"
                name="name"
                onChange={handelInput}
                value={name}
                required
              />
            </span>

            <span className="flex h-[12%] w-full flex-col">
              <label htmlFor="email">Email</label>
              <input
                name="email"
                className="h-10 border-b-2 border-gray-200 bg-transparent outline-none transition-colors duration-500  focus:outline-none sm:focus:border-black md:focus:border-white  "
                type="email"
                value={email}
                onChange={handelInput}
                required
              />
            </span>
            <span className="flex h-[12%] w-full flex-col">
              <label htmlFor="phoneNumber">Phone Number</label>
              <input
                name="phoneNumber"
                className=" h-10 border-b-2 border-gray-200 bg-transparent outline-none transition-colors duration-500 sm:focus:border-black md:focus:border-white "
                type="number"
                onChange={handelInput}
                value={phoneNumber}
                required
              />
            </span>
            <span className="flex h-1/4 w-full flex-col">
              <label htmlFor="message">Message</label>

              <textarea
                name="message"
                className="hide-scrollbar mt-3  resize-none border-b-2 border-gray-200 bg-transparent outline-none transition-colors duration-500 sm:focus:border-black md:focus:border-white "
                id=""
                onChange={handelInput}
                value={message}
                required
              ></textarea>
            </span>

            <button
              className={` flex items-center justify-center gap-4 rounded-xl  ${loading ? 'bg-[#77b3f7]' : 'bg-[rgba(21,101,192,1)]'}  px-5 py-[0.938rem] align-middle  text-sm leading-4  text-white  `}
              disabled={loading}
            >
              {loading ? <>{<Spinner size="sm" />} Sending</> : 'Send'}
            </button>
          </form>
        </div>
      </section>
    </>
  );
};
