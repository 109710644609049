import practising from '@/assets/foreigner-practising.jpeg';
import { ContentLayout, PageHeader } from '@/components/Layouts';
import { Spinner } from '@/components/Spinner';
import axios from 'axios';
import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { MdClose } from 'react-icons/md';

const youtubeLinks = [
  'g4eC82QFiQU',
  'K5eeyalvI9s',
  'Th4QThnNTSM',
  'aHPMi4bb-jM',
];
const navlinks = [
  {
    title: 'Overseas',
    folderId: '17wmHvXBWg2FBsNnFLkwDnFZVyy-d8cJ1',
  },
  {
    title: 'In the dojo',
    folderId: '1UnmvXfoXbqo-X7qKBXPKqxpf8ZhWV3Qs',
  },
  {
    title: 'Outdoor Sessions',
    folderId: '16RzS2ssD_P9Gq-rOe4apgI2PKYDl_JRl',
  },
  {
    title: 'Special Occasions',
    folderId: '1qTSLCUAP9rYjU1OKaj5rvcjk8TE5bm3x',
  },
];
const API_KEY = 'AIzaSyDOXQru0N9dDNeusCx8mK3NDUcI-Rh6wFw';

const ModalContext = createContext<
  Dispatch<SetStateAction<{ isOpen: boolean; imageUrl: string }>> | undefined
>(undefined);
export const Gallery = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [activeNav, setActiveNav] = useState(0);
  const [nextPageToken, setNextPageToken] = useState<string | null>(null);
  const [images, setImages] = useState<string[][]>([]);
  const handleNavClick = (index: number) => {
    setActiveNav(index);
  };

  const [modal, setModal] = useState({
    isOpen: false,
    imageUrl: '',
  });

  const fetchImages = async (pageToken?: string) => {
    if (!pageToken) {
      setImages([]);
    }
    setIsLoading(true);
    const response = await axios.get(
      `https://www.googleapis.com/drive/v3/files?q='${navlinks[activeNav].folderId}'+in+parents&key=${API_KEY}&fields=files(id,name,mimeType),nextPageToken&pageSize=${10}${pageToken ? `&pageToken=${pageToken}` : ''}`,
    );
    const imageUrls = response.data.files.map(
      (file: any) =>
        `https://drive.google.com/thumbnail?id=${file.id}&sz=w1000`,
    );
    const newImages: string[][] = [];
    for (let i = 0; i < imageUrls.length; i += 5) {
      const chunk = imageUrls.slice(i, i + 5);
      newImages.push(chunk);
    }

    setImages((prevImages) => [...prevImages, ...newImages]);
    setNextPageToken(response.data.nextPageToken || null);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchImages();
  }, [activeNav]);

  useEffect(() => {
    if (modal.isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [modal.isOpen]);

  return (
    <>
      <PageHeader image={practising} title="Gallery " imageStyles={``} />
      <GalleryModal
        isOpen={modal.isOpen}
        imageUrl={modal.imageUrl}
        onClose={() => setModal({ imageUrl: '', isOpen: false })}
      />
      <ContentLayout>
        <section className="flex items-center justify-center p-[3.75rem] text-center ">
          <p className="text-base font-normal leading-[1.6rem]">
            {`Welcome to the gallery, a glimpse into my daily routine as a martial
            artist and coach. Here, you'll find snapshots and videos capturing
            moments from training sessions, competitions, and everyday life in
            the dojo.`}
          </p>
        </section>
        <section className="flex flex-col gap-y-[3.125rem]">
          <div className="hide-scrollbar flex justify-start gap-x-2.5   overflow-scroll sm:justify-start lg:justify-center">
            {navlinks.map(({ title }, index) => (
              <NavButton
                key={title}
                onClick={() => handleNavClick(index)}
                title={title}
                isActive={activeNav === index}
              />
            ))}
          </div>
          <div className="flex flex-col gap-y-[1.563rem]">
            <ModalContext.Provider value={setModal}>
              <GalleryImage images={images} />

              {!isLoading && nextPageToken && (
                <div className="flex items-center justify-center">
                  <button
                    onClick={() => fetchImages(nextPageToken)}
                    className="rounded-xl border border-[#1565C0] px-5 py-[0.938rem]"
                  >
                    Load More
                  </button>
                </div>
              )}
            </ModalContext.Provider>
            {isLoading && (
              <div className="flex h-24 w-full items-center justify-center">
                <Spinner size="lg" />
              </div>
            )}
          </div>
        </section>
        <section className="flex flex-col items-center justify-center gap-y-9 py-[3.75rem]">
          <h1 className="font-bold leading-[3.657rem] sm:text-4xl md:text-5xl">
            Youtube
          </h1>

          <div className="flex flex-wrap items-center justify-center gap-[1.688rem]">
            {youtubeLinks.map((link, index) => (
              <iframe
                key={link}
                className="h-[19.688rem] w-full max-w-[30.313rem]"
                src={`https://www.youtube.com/embed/${link}`}
                title="YouTube video player"
                allowFullScreen
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
              ></iframe>
            ))}
          </div>
        </section>
      </ContentLayout>
    </>
  );
};

type NavButtonProps = { title: string; isActive: boolean; onClick: () => void };

const NavButton = ({ title, isActive, onClick }: NavButtonProps) => {
  return (
    <button
      onClick={onClick}
      className={`${
        isActive ? 'bg-[#1D47A1] font-semibold' : 'bg-[#4584CC] font-normal'
      } shrink-0 rounded-xl text-center  leading-[1.829rem] text-white transition-all sm:px-6 sm:py-2.5 sm:text-xl  md:px-10  md:py-5  `}
    >
      {title}
    </button>
  );
};

type GalleryImageProps = {
  images: string[][];
};
const GalleryImage = ({ images }: GalleryImageProps) => {
  return (
    <>
      {images.map((images, index) => (
        <GalleryRow key={index} images={images} reverse={index % 2 !== 0} />
      ))}
    </>
  );
};

type GalleryRowProps = {
  reverse: boolean;
  images: string[];
};
const GalleryRow = ({ images, reverse }: GalleryRowProps) => {
  const setModal = useContext(ModalContext);
  if (!setModal) {
    throw new Error('setModal should be used within a modal provider');
  }
  return (
    <div
      className={`flex cursor-pointer flex-nowrap sm:h-auto sm:flex-col md:h-[30.75rem] md:flex-row md:gap-x-[1.563rem] ${reverse && 'md:flex-row-reverse'}`}
    >
      <img
        onClick={(e: React.MouseEvent) => {
          e.stopPropagation();
          setModal({ imageUrl: images[0], isOpen: true });
        }}
        src={images[0]}
        className="h-full object-cover p-1 sm:w-full md:w-1/2"
      />
      <div className="flex flex-row flex-wrap  sm:w-full md:w-1/2">
        {images.map(
          (image, index) =>
            index > 0 && (
              <img
                onClick={(e: React.MouseEvent) => {
                  e.stopPropagation();
                  setModal({ imageUrl: image, isOpen: true });
                }}
                src={image}
                key={image}
                className="size-1/2  object-cover p-1"
              />
            ),
        )}
      </div>
    </div>
  );
};

type GalleryModalProps = {
  isOpen: boolean;
  imageUrl: string;
  onClose: () => void;
};
const GalleryModal = ({ imageUrl, isOpen, onClose }: GalleryModalProps) => {
  const ref = useRef<HTMLImageElement | null>(null);
  useEffect(() => {
    const handleClickoutside = (e: MouseEvent) => {
      if (ref.current && isOpen && !ref.current.contains(e.target as Node)) {
        onClose();
      }
    };
    document.addEventListener('click', handleClickoutside);
  }, [isOpen, onClose]);
  return (
    <div
      className={`${isOpen ? 'fixed' : 'hidden'} inset-0 z-40 bg-[rgba(0,0,0,0.90)] shadow-[0_4px_30px_rgba(0,0,0,0.1)]`}
    >
      <div className="relative flex size-full items-center justify-center  ">
        <MdClose
          size={30}
          className="absolute right-5 top-10 z-50 cursor-pointer text-white"
          onClick={onClose}
        />
        <img
          ref={ref}
          src={imageUrl}
          className="size-auto max-h-[80vh] max-w-[80vw] "
        />
      </div>
    </div>
  );
};
