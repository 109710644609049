import { NavLink } from 'react-router-dom';
import { navigation } from './MainLayout';
import { useEffect } from 'react';
import { motion } from 'framer-motion';
import { MdClose } from 'react-icons/md';

type SidebarProps = {
  isOpen: boolean;
  onClose: () => void;
};
const parentVariant = {
  open: {
    x: 0,
    transition: {
      duration: 0.3,
      when: 'beforeChildren',
      staggerChildren: 0.1,
    },
  },
  hidden: {
    x: '-100%',
    transition: {
      duration: 0.3,
      staggerChildren: 0.1,
      when: 'afterChildren',
    },
  },
};

const childrenVariant = {
  open: {
    y: 0,
    x: 0,
    opacity: 1,
  },
  hidden: {
    y: 100,
    opacity: 0,
    transition: {
      duration: 0.1,
    },
    x: -100,
  },
};
export const Sidebar = ({ isOpen, onClose }: SidebarProps) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);

  return (
    <motion.aside
      exit={'hidden'}
      initial={'hidden'}
      animate={isOpen ? 'open' : 'hidden'}
      variants={parentVariant}
      className="fixed z-50  h-screen w-full bg-black text-white  "
    >
      <div className="relative size-full ">
        <MdClose
          size={30}
          className="absolute right-5 top-10"
          onClick={onClose}
        />

        <ul className="flex size-full flex-col items-center justify-center gap-y-4">
          {navigation.map(({ name, to }) => (
            <motion.li key={name} variants={childrenVariant}>
              <NavLink
                className={({ isActive }) =>
                  isActive ? 'text-red-600 text-2xl ' : 'text-2xl '
                }
                onClick={onClose}
                to={to}
              >
                {name}
              </NavLink>
            </motion.li>
          ))}
        </ul>
      </div>
    </motion.aside>
  );
};
