import { Notifications } from '@/components/Notification';
import { AnimatePresence } from 'framer-motion';
import { useEffect, useState } from 'react';
import { MdDehaze } from 'react-icons/md';
import {
  Link,
  NavLink,
  Outlet,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { Sidebar } from './Sidebar';

export const navigation = [
  { name: 'Home', to: 'home' },
  { name: 'About', to: 'about' },
  { name: 'Gallery', to: 'gallery' },
  { name: 'Achievements', to: 'achievements' },
  { name: 'Contact', to: 'contact' },
];

const MainLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (location.pathname === '/') {
      navigate('/home');
    }
  }, [location.pathname, navigate]);

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  return (
    <>
      <Notifications />
      <AnimatePresence>
        {isSidebarOpen ? (
          <Sidebar
            onClose={() => setIsSidebarOpen(false)}
            isOpen={isSidebarOpen}
          />
        ) : null}
      </AnimatePresence>
      <nav
        className={`${
          location.pathname !== '/home'
            ? 'bg-[rgba(0,0,0,0.50)] shadow-[0_4px_30px_rgba(0,0,0,0.1)]'
            : ' '
        } absolute top-0 z-30 flex h-[4.375rem]  w-full items-center  justify-center px-5 text-white `}
      >
        <div className="flex w-full  max-w-[71.25rem] items-center justify-between">
          {/* logo */}
          <Link className="text-[1.75rem] font-bold " to={'/'}>
            RUPAK
          </Link>

          {/* navigation */}
          <div className="w-[33.75rem] justify-around text-[1rem] sm:hidden md:flex">
            {navigation.map((item) => (
              <NavLink
                className={({ isActive }) =>
                  isActive
                    ? 'text-red-600 transition-all duration-500 select-none'
                    : 'transition-all duration-400 select-none'
                }
                to={item.to}
                key={item.name}
              >
                {item.name}
              </NavLink>
            ))}
          </div>
          <MdDehaze
            size={30}
            onClick={() => setIsSidebarOpen(true)}
            className="text-white sm:block md:hidden"
          />
        </div>
      </nav>
      <Outlet />
      <footer className="flex w-full flex-col items-center justify-center border-t border-t-[rgba(33,33,33,0.5)]  py-5 text-center text-[0.875rem] font-normal">
        <p>&copy; 2024 Rupak Nepali. All rights reserved.</p>
        <p>rupaknepali2053@gmail.com</p>
        <p>Rangasala, Pokhara</p>
      </footer>
    </>
  );
};

export default MainLayout;
