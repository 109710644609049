import MainLayout from '@/components/Layouts/MainLayout';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Home } from './Home';
import Aboutus from './Aboutus';
import { Gallery } from './Gallery';
import Achivements from './Achivements';
import { Contact } from './Contact';

const router = createBrowserRouter([
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'home', element: <Home /> },
      { path: 'about', element: <Aboutus /> },
      {
        path: 'gallery',
        element: <Gallery />,
      },
      { path: 'achievements', element: <Achivements /> },
      { path: 'contact', element: <Contact /> },
    ],
  },
]);

const RootRoute = () => {
  return <RouterProvider router={router} />;
};

export default RootRoute;
