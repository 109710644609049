import beltIcon from '@/assets/icons/belt.png';
import boxingGlovesIcon from '@/assets/icons/boxinggloves.png';
import clockIcon from '@/assets/icons/clock.png';
import targetIcon from '@/assets/icons/target.png';
import yogaIcon from '@/assets/icons/yoga.png';
import rupakGuruFighting from '@/assets/rupak-guru-fighting.png';
import rupakGuruPose from '@/assets/rupak-guru-pose.png';
import rupakGuruStandingPose from '@/assets/rupak-guru-standing-pose.png';
import { ContentLayout } from '@/components/Layouts';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

const aboutMeParentVariants = {
  hidden: {
    scale: 0,
  },
  visible: {
    scale: 1,
    transition: {
      duration: 0.3,
      delayChildren: 0.3,
    },
  },
};

const aboutMeChildrenVariants = {
  hidden: {
    scale: 0,
  },
  visible: {
    scale: 1,
    transition: {
      duration: 0.6,
    },
  },
};

const trainingApproaches: TrainingApproachCardProps[] = [
  {
    title: 'Training Philosophy',
    description:
      'Focus on discipline, respect, and overall growth of mind and body.',
    image: yogaIcon,
  },
  {
    title: 'Teaching Style',
    description:
      'Focus on discipline, respect, and overall growth of mind and body.',
    image: boxingGlovesIcon,
  },
  {
    title: 'Focus Area',
    description:
      'Focus on discipline, respect, and overall growth of mind and body.',

    image: targetIcon,
  },
  {
    title: 'Class Structure',
    description:
      'Focus on discipline, respect, and overall growth of mind and body.',

    image: clockIcon,
  },
  {
    title: 'Special Techniques',
    description:
      'Focus on discipline, respect, and overall growth of mind and body.',

    image: beltIcon,
  },
];

const experiences = [
  {
    title: 'Kick-Boxing',
    years: '10+years',
    images: [rupakGuruFighting, rupakGuruFighting, rupakGuruFighting],
  },
  {
    title: 'Kyokushin Karate',
    years: '17+years',
    images: [rupakGuruFighting, rupakGuruFighting, rupakGuruFighting],
  },
  {
    title: 'Muay Thai',
    years: '10+years',
    images: [rupakGuruFighting, rupakGuruFighting, rupakGuruFighting],
  },
];
export const Home = () => {
  const navigate = useNavigate();
  return (
    <>
      <section className="flex h-[calc(100svh)]  scroll-mt-[4.375rem] border border-black bg-[#111111] text-white ">
        <div className="z-20 flex h-full items-center px-5 sm:w-full md:w-full md:justify-start lg:w-1/2 lg:justify-end ">
          <div className="flex flex-col">
            <h6 className="text-xl font-medium leading-[1.438rem]">Hi, I am</h6>
            <h1 className="font-extrabold leading-[4.6rem] sm:text-5xl md:text-[4rem] ">
              RUPAK NEPALI
            </h1>
            <p className="text-xl font-medium leading-8">
              Unleash Your Hsmidden Strength Potential. Transform Your Body
            </p>
            <p className="text-xl font-medium">
              and Mind with Strength Training.
            </p>
          </div>
        </div>
        <div className="absolute inset-x-0 bottom-0 z-10 h-[calc(100%-4.375rem)] bg-[rgba(0,0,0,0.2)] lg:hidden"></div>
        <img
          src={rupakGuruPose}
          alt="Rupak guru"
          className="absolute bottom-0 right-0 h-full max-h-[calc(100%-4.375rem)] sm:w-full sm:object-fill md:w-auto md:object-cover"
        />
      </section>
      <ContentLayout>
        <section className="flex size-full min-h-screen  items-center  justify-center  py-[3.75rem] text-black sm:flex-col sm:gap-y-3 sm:pt-[9.375rem] md:flex-row md:gap-x-24 md:pt-[5.625rem] ">
          <div className="flex items-center justify-center sm:w-full md:w-1/2">
            <motion.div
              variants={aboutMeParentVariants}
              initial={'hidden'}
              whileInView={'visible'}
              transition={{
                duration: 0.5,
              }}
              className="flex size-full max-h-[34.813rem] max-w-[34.813rem] justify-center rounded-[50%]  bg-[#E3F2FD]"
            >
              <motion.img
                variants={aboutMeChildrenVariants}
                src={rupakGuruStandingPose}
                alt="Rupak guru standing pose"
                className="mt-[-90px] size-full max-h-[40.375rem] max-w-[34.813rem] origin-bottom rounded-bl-[276px] rounded-br-[277px]"
              />
            </motion.div>
          </div>
          <div className="flex flex-col gap-y-6 sm:w-full sm:items-center sm:text-center md:w-1/2 md:items-start md:text-start">
            <h1 className="font-bold leading-[3.45rem] sm:text-4xl lg:text-5xl">
              About MySelf
            </h1>
            <p className="text-base font-normal  leading-[1.6rem]">
              {`Karate, boxing, Muay Thai - they've all been a part of my life for
              as long as I can remember. Winning titles is a bonus, but the real
              satisfaction comes from pushing my limits every day, and hopefully
              inspiring others to do the same. I'm just a regular guy who loves
              martial arts and share his passion with the next generation.`}
            </p>
            <button className="w-fit rounded-xl border-2 border-[#1565C0] px-5  py-[0.938rem] text-sm font-normal leading-none text-[#1565C0]">
              Learn More
            </button>
          </div>
        </section>
        <section className="flex  min-h-screen  flex-col items-center justify-center gap-y-[3.25rem]  py-[3.75rem] text-black">
          <h1 className="text-center font-bold leading-[3.631rem] sm:text-4xl md:text-5xl">
            My Experience
          </h1>
          <p className="text-center text-base font-normal leading-[1.21rem]">
            Martial arts practitioner with over 17 years of experience.
            Specializing in Karate, Kickboxing, and Muay Thai.
          </p>

          <div className="flex w-full flex-col  gap-y-[5.313rem] overflow-hidden ">
            {experiences.map(({ images, title, years }, index) => (
              <ExperienceCard
                key={index}
                years={years}
                reverse={index % 2 !== 0}
                title={title}
                images={images}
              />
            ))}
          </div>
        </section>
        <section className="flex h-auto flex-col items-center justify-center gap-y-[3.125rem]  py-[3.75rem] text-black">
          <h1 className="text-center font-bold leading-[3.45rem] sm:text-4xl md:text-5xl">
            Training Approach
          </h1>
          <div className="flex flex-col sm:gap-y-[1.125rem]  md:gap-y-[4.559rem]">
            <div className="flex items-center justify-center gap-y-[1.125rem] sm:flex-col  md:flex-row md:gap-x-[8.313rem] ">
              {trainingApproaches.map(
                ({ description, image, title }, index) =>
                  index < 3 && (
                    <TrainingApproachCard
                      key={title}
                      title={title}
                      description={description}
                      image={image}
                    />
                  ),
              )}
            </div>
            <div className="flex items-center justify-center sm:flex-col sm:gap-y-[1.125rem]  md:flex-row md:gap-x-[8.313rem] ">
              {trainingApproaches.map(
                ({ description, image, title }, index) =>
                  index > 2 && (
                    <TrainingApproachCard
                      key={title}
                      title={title}
                      description={description}
                      image={image}
                    />
                  ),
              )}
            </div>
          </div>
        </section>
      </ContentLayout>

      <section className="relative bg-rupakGuruKicking bg-cover bg-no-repeat before:absolute before:inset-0 before:z-10 before:bg-[rgba(0,0,0,0.5)] before:content-['']">
        <div className="relative  z-20 mx-auto flex max-w-[71.25rem] flex-col items-center justify-center gap-y-[1.188rem] px-[2.188rem] py-[7.5rem] text-white">
          <h1 className="text-center text-4xl leading-[3.6rem]">
            Guiding you to become the best version of yourself is my passion and
            commitment.
          </h1>
          <button
            onClick={() => navigate('/contact')}
            className=" rounded-xl border-2  px-5 py-[0.938rem] text-sm font-normal leading-[1.006rem] "
          >
            Contact me
          </button>
        </div>
      </section>
    </>
  );
};

type TrainingApproachCardProps = {
  title: string;
  image: string;
  description: string;
};
const TrainingApproachCard = ({
  description,
  image,
  title,
}: TrainingApproachCardProps) => {
  return (
    <div className="flex w-full max-w-[18.188rem] flex-col items-center justify-center gap-y-3 text-center">
      <div className="flex h-[7.25rem] w-[6.875rem] items-center justify-center rounded-[50%] bg-[#E3F2FD]">
        <img src={image} className="w-[6.25rem]] h-[6.25rem]" alt="Yoga" />
      </div>

      <h2 className="font-bold leading-[1.725rem]  sm:text-xl md:text-2xl">
        {title}
      </h2>
      <p className="text-base font-normal leading-[1.4rem]">{description}</p>
    </div>
  );
};

type ExperienceCardProps = {
  title: string;
  images: string[];
  reverse: boolean;
  years: string;
};
const ExperienceCard = ({
  images,
  title,
  reverse,
  years,
}: ExperienceCardProps) => {
  return (
    <motion.div
      initial={{
        x: reverse ? '90%' : '-90%',
        opacity: 0,
      }}
      whileInView={{
        x: 0,
        opacity: 1,
        transition: {
          duration: 0.9,
        },
      }}
      className={`${
        reverse ? ' md:flex-row-reverse' : ''
      }  flex items-center sm:flex-col  md:flex-row md:gap-x-[1.81rem]  lg:gap-x-[3.625rem]  `}
    >
      <div className={`${reverse ? 'md:text-end' : ' '} sm:text-center`}>
        <h2 className="font-bold leading-[2.421rem] sm:text-2xl md:text-[2rem]">
          {title}
        </h2>
        <span className="text-base leading-[1.6rem]">{years}</span>
      </div>
      <div className="flex sm:gap-x-1 md:gap-x-[1.81rem]  lg:gap-x-[3.625rem]">
        {images.map((image, index) => (
          <div key={index} className="shrink">
            <img
              className="size-full max-h-[18.25rem] max-w-[15.75rem] rounded-[1.25rem]"
              src={image}
              alt=""
            />
          </div>
        ))}
      </div>
    </motion.div>
  );
};
