import achivementFill from '@/assets/achivement placeholder.jpg';
import achivement1 from '@/assets/achivement1.jpeg';
import achivement2 from '@/assets/achivement 2.jpeg';
import achivement3 from '@/assets/achivement3.jpeg';

import achivementHeaderImg from '@/assets/achivements.jpg';
import splashImage from '@/assets/strokeimage.png';
import { ContentLayout, PageHeader } from '@/components/Layouts';
import { useState } from 'react';

const padding = 'md:py-[3.75rem] sm:py-8';

export const Achievements = () => {
  const [achivementCount, setAchivementCount] = useState(5);

  const handelLoadmore = (
    event: React.MouseEvent<HTMLButtonElement> | null,
  ) => {
    console.log(achivementCount);

    setAchivementCount(achivementCount + 5);
  };

  const achivementDetails = [
    {
      img: achivement1,
      achivementTitle:
        '1st Prime Minister Cup & 3rd National Muay Thai Championship- 2073',
    },
    {
      img: achivement2,
      achivementTitle: '3rd K-BAG International Muay Thai Fight-2018.',
    },
    {
      img: achivement3,
      achivementTitle: '8th National Game -2076 Nepalgunj (Kyokushin karate)',
    },
    {
      img: achivement1,
      achivementTitle:
        '1st Prime Minister Cup & 3rd National Muay Thai Championship- 2073',
    },
    {
      img: achivement2,
      achivementTitle: '3rd K-BAG International Muay Thai Fight-2018.',
    },
    {
      img: achivement3,
      achivementTitle: '8th National Game -2076 Nepalgunj (Kyokushin karate)',
    },
    {
      img: achivement1,
      achivementTitle:
        '1st Prime Minister Cup & 3rd National Muay Thai Championship- 2073',
    },
    {
      img: achivement2,
      achivementTitle: '3rd K-BAG International Muay Thai Fight-2018.',
    },
    {
      img: achivement3,
      achivementTitle: '8th National Game -2076 Nepalgunj (Kyokushin karate)',
    },
    {
      img: achivement1,
      achivementTitle:
        '1st Prime Minister Cup & 3rd National Muay Thai Championship- 2073',
    },
    {
      img: achivement2,
      achivementTitle: '3rd K-BAG International Muay Thai Fight-2018.',
    },
    {
      img: achivement3,
      achivementTitle: '8th National Game -2076 Nepalgunj (Kyokushin karate)',
    },
    {
      img: achivement1,
      achivementTitle:
        '1st Prime Minister Cup & 3rd National Muay Thai Championship- 2073',
    },
    {
      img: achivement2,
      achivementTitle: '3rd K-BAG International Muay Thai Fight-2018.',
    },
    {
      img: achivement3,
      achivementTitle: '8th National Game -2076 Nepalgunj (Kyokushin karate)',
    },
    {
      img: achivement2,
      achivementTitle: '3rd K-BAG International Muay Thai Fight-2018.',
    },
    {
      img: achivement3,
      achivementTitle: '8th National Game -2076 Nepalgunj (Kyokushin karate)',
    },
  ];

  return (
    <>
      <PageHeader image={achivementHeaderImg} title="Achivements" />

      <ContentLayout>
        <p
          className={`text-center  ${padding}  text-[1rem] font-normal leading-[1.6rem]`}
        >
          I will outline some crucial moments and achievements that have had an
          impact in my martial arts journey. While I appreciate the
          acknowledgment and opportunities that I’ve received, I believe real
          success is about continually learning and growing. These
          accomplishments are more than just personal triumphs; they are also
          testaments of the assistance and advice provided to me by my teachers,
          pupils, and society.
        </p>

        {/* achievements sections */}

        {achivementDetails
          .slice(0, achivementCount)
          .map(({ achivementTitle, img }, index) => (
            <AchivementCard
              key={index}
              reverse={index % 2 != 0}
              achivmentTitle={achivementTitle}
              img={img}
            />
          ))}

        <span
          className={`mb-16 flex w-full justify-center ${achivementCount >= achivementDetails.length ? 'hidden' : 'block'}`}
        >
          <button
            className="rounded-xl border-2 border-[rgba(21,101,192,1)] px-5 py-4  text-sm font-normal leading-4 text-[rgba(21,101,192,1)]"
            onClick={handelLoadmore}
          >
            Load More
          </button>
        </span>
      </ContentLayout>
    </>
  );
};

type AchivementCardProps = {
  img: string;
  achivmentTitle: string;
  reverse: boolean;
};

const AchivementCard = ({
  img,
  achivmentTitle,
  reverse,
}: AchivementCardProps) => {
  return (
    <div
      className={`flex w-full  ${padding} ${
        reverse ? 'md:flex-row-reverse' : 'md:flex-row'
      }   sm:flex-col md:gap-x-[1.813rem] `}
    >
      <div className=" h-[27.875rem] sm:flex-[100%] md:flex-[60%] ">
        <img
          className="size-full rounded-[1.375rem] object-cover"
          src={img}
          alt=""
        />
      </div>
      <div className="relative flex w-full flex-col items-center justify-center text-center  sm:flex-[100%] md:flex-[50%]  ">
        <img
          src={splashImage}
          alt=""
          className="size-full overflow-hidden object-cover [transform:translate(20px,0px)_scale(1)]"
        />
        <h1
          className=" absolute text-center  font-semibold sm:text-xl sm:leading-10 md:text-[2rem]
         "
        >
          {achivmentTitle}
        </h1>
      </div>
    </div>
  );
};

export default Achievements;
